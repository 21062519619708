import { HttpClient } from '@angular/common/http';
import { afterNextRender, Component, inject, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@env/environment';
import { Group } from 'app/core/models/model.model';
import { AuthService } from 'app/core/services/auth/auth.service';
import { InputAreaFileComponent } from 'app/shared/components/input-area-file/input-area-file.component';
import { promptToDownloadBlob } from 'app/utils/file.utils';
import JSZip from 'jszip';
import { Watermark } from 'libs/watermarkjs';
import { EWatermarkPosition } from 'libs/watermarkjs/types/watermark';
import { finalize, Observable, of } from 'rxjs';
import { AlertComponent } from '../../shared/components/alert/alert.component';
import { CheckboxComponent } from '../../shared/components/checkbox/checkbox.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
@Component({
  selector: 'casa-doc-watermark',
  standalone: true,
  imports: [
    InputAreaFileComponent,
    FormsModule,
    ReactiveFormsModule,
    AlertComponent,
    LoaderComponent,
    CheckboxComponent,
  ],
  templateUrl: './watermark.component.html',
  styleUrl: './watermark.component.scss',
})
export class WatermarkComponent {
  #authService = inject(AuthService);
  #httpClient = inject(HttpClient);
  #agencyGroup: Group;
  fileControl = new FormControl<File[]>([], { nonNullable: true });
  isLoading = signal<boolean>(false);
  warningMessage = signal<string>(null);
  canAddCompanyLogo = signal<boolean>(false);
  addCompanyLogoControl = new FormControl(false);

  constructor() {
    afterNextRender(() => {
      let obs$: Observable<{ data: Group }>;
      const groupId = this.#authService.user?.agentAgency?.group?.documentId;
      if (groupId) {
        this.isLoading.set(true);
        obs$ = this.#httpClient
          .get<{ data: Group }>(`${environment.apiUrl}/gruppos/${groupId}`, {
            params: {
              populate: 'companyLogo',
            },
          })
          .pipe(finalize(() => this.isLoading.set(false)));
      } else {
        obs$ = of({ data: null });
      }

      obs$.subscribe((response) => {
        this.#agencyGroup = response.data;
        if (!this.#agencyGroup) {
          this.warningMessage.set(
            "La tua agenzia non ha un gruppo associato. Contatta l'amministratore per risolvere il problema."
          );
        } else if (!this.#agencyGroup.casaDocLogoPosition) {
          this.warningMessage.set(
            "Non è stata impostata la posizione del logo Casa-Doc per il watermark. Contatta l'amministratore per risolvere il problema."
          );
        }
        this.canAddCompanyLogo.set(
          !!this.#agencyGroup?.companyLogo &&
            !!this.#agencyGroup?.companyLogoPosition &&
            !!this.#agencyGroup?.companyLogoSizePercentage
        );
      });
    });
  }

  async onWatermark() {
    try {
      this.isLoading.set(true);
      const promises = this.fileControl.value.map(async (file) => {
        const watermark = await Watermark.load(file);
        if (this.#agencyGroup?.casaDocLogoPosition) {
          await watermark.applyImage({
            path: 'images/casadoc_watermark_white_small.png',
            position: EWatermarkPosition[this.#agencyGroup.casaDocLogoPosition],
            // offsetScale: 0.01, // add a 2% offset of the image width/height
            scale: 0.1, // make the watemark as 20% of the image width/height
            offset: 10,
          });
        }

        if (this.canAddCompanyLogo() && this.addCompanyLogoControl.value) {
          await watermark.applyImage({
            path: this.#agencyGroup.companyLogo.url,
            position: EWatermarkPosition[this.#agencyGroup.companyLogoPosition],
            offsetScale: 0.02, // add a 2% offset of the image width/height
            scale: this.#agencyGroup.companyLogoSizePercentage / 100,
          });
        }

        await watermark.enhanceImage({
          brightnessFactor: 1.1, // +10%
          contrastFactor: 1.2, // +20%
          saturationFactor: 1.2, // +20%
        });
        return watermark.getResult();
      });

      const files = await Promise.all(promises);
      const zip = new JSZip();
      files.forEach((file) => {
        zip.file(file.name, file, { base64: true });
      });
      const zipFile = await zip.generateAsync({ type: 'blob' });
      promptToDownloadBlob(zipFile, 'zip', 'casa-doc-watermark-' + Date.now());
      this.fileControl.reset([]);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading.set(false);
    }
  }
}
